import "@babel/polyfill";
import { window_size, header_position } from "./modules/theme";
import { viewport_less_than_360 } from "./modules/viewport";
import { humburger_menu, drawer_menu } from "./modules/humburger-menu";
import { scroll_hint } from "./modules/scrollhint";
import { scroll_in_animation } from "./modules/animation";
import { autoScrollSplide, thumbsListSplide } from "./modules/splide";

window_size();
viewport_less_than_360();
humburger_menu();
drawer_menu();
scroll_hint();
header_position();
scroll_in_animation();
autoScrollSplide();
thumbsListSplide();
