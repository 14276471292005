/**
 * JavaScript for Theme setting.
 */

// window size
export function window_size() {
  let windowWidth;
  let windowheight;
  let scrollbarWidth;
  let headerheight;
  let footerheight;
  let breadcrumbsheight;

  // ページ読み込み時
  window.addEventListener('DOMContentLoaded', function(){
    // ブラウザ内の表示域(スクロールバーを除く)
    windowWidth = document.documentElement.clientWidth;
    windowheight = document.documentElement.clientHeight;
    scrollbarWidth = window.innerWidth - windowWidth;
    headerheight = document.getElementById('site-header').clientHeight;
    footerheight = document.getElementById('site-footer').clientHeight;
    if ( document.getElementById('breadcrumbs') === null ) {
      breadcrumbsheight = 0;
    } else {
      breadcrumbsheight = document.getElementById('breadcrumbs').clientHeight;
    }

    document.documentElement.style.setProperty('--window-width', windowWidth);
    document.documentElement.style.setProperty('--window-height', windowheight);
    document.documentElement.style.setProperty('--window-width-px', (windowWidth) + 'px');
    document.documentElement.style.setProperty('--window-height-px', (windowheight) + 'px');
    document.documentElement.style.setProperty('--header-height-px', (headerheight) + 'px');
    document.documentElement.style.setProperty('--footer-height-px', (footerheight) + 'px');
    document.documentElement.style.setProperty('--scrollbar-width-px', (scrollbarWidth) + 'px');
  });

  // ウィンドウのサイズ変更イベントの設定
  window.addEventListener('resize', function(){
    windowWidth = document.documentElement.clientWidth;
    windowheight = document.documentElement.clientHeight;
    scrollbarWidth = window.innerWidth - windowWidth;
    headerheight = document.getElementById('site-header').clientHeight;
    footerheight = document.getElementById('site-footer').clientHeight;
    if ( document.getElementById('breadcrumbs') === null ) {
      breadcrumbsheight = 0;
    } else {
      breadcrumbsheight = document.getElementById('breadcrumbs').clientHeight;
    }
    document.documentElement.style.setProperty('--window-width', windowWidth);
    document.documentElement.style.setProperty('--window-height', windowheight);
    document.documentElement.style.setProperty('--window-width-px', (windowWidth) + 'px');
    document.documentElement.style.setProperty('--window-height-px', (windowheight) + 'px');
    document.documentElement.style.setProperty('--header-height-px', (headerheight) + 'px');
    document.documentElement.style.setProperty('--footer-height-px', (footerheight) + 'px');
    document.documentElement.style.setProperty('--scrollbar-width-px', (scrollbarWidth) + 'px');
  });
}

// header position
export function header_position() {
  document.addEventListener('DOMContentLoaded', function() {
    window.addEventListener('scroll', function() {
      // ページ上部からのスクロール量を取得
      var scrollPosition = window.scrollY;
      // header要素を取得
      var header = document.querySelector('header');
      if (scrollPosition > 20) {
        // スクロール量が5pxより大きい場合、headerにdata-move="true"を設定
        header.setAttribute('data-move', 'true');
      } else {
        // スクロール量が5px以下の場合、data-move属性を削除
        if (header.hasAttribute('data-move')) {
          header.removeAttribute('data-move');
        }
      }
    });
  });
}
