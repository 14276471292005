/**
 * Splide
 */

import Splide from '@splidejs/splide';

// MV
export function autoScrollSplide() {
  document.addEventListener( 'DOMContentLoaded', function() {

    var splideElement = document.querySelector('.js-auto-splide-slider .splide');

    if( splideElement ) {
      const options = {
        type: "loop",
        arrows: false,
        pagination: false,
        drag: false,
        gap: 20,
        perPage: 2,
        breakpoints: {
          900: {
            perPage: 2,
            gap: 15,
          },
          600: {
            perPage: 2,
            gap: 10,
          },
        },
        autoScroll: {
          speed: 0.5,
          pauseOnHover: false,
        },
      };
      const splide = new Splide(".js-auto-splide-slider .splide", options);
      splide.mount(window.splide.Extensions);
    }
  });

}


// thumbs-list
export function thumbsListSplide() {
  document.addEventListener( 'DOMContentLoaded', function() {

    var splideElement = document.querySelector('.js-splide-slider .splide');

    if( splideElement ) {
      const options = {
        type: "fade",
        rewind: true,
        arrows: true,
        pagination: true,
        drag: false,
        speed: 600,
        perPage: 1,
        autoplay: true,
        // autoScroll: true,
      };
      const splide = new Splide(".js-splide-slider .splide", options);
      splide.mount();
    }
  });

}
