/**
 * Animation
 */

export function scroll_in_animation() {

  document.addEventListener('DOMContentLoaded', function() {
    // Target elements with specific classes
    var targets = document.querySelectorAll('.fade-in, .fade-in-up, .slide-in-to-right, .slide-in-to-left');
    var offset = -80; // Animation timing offset

    // Function to check and apply animations
    function checkAnimation() {
      var scroll = window.scrollY; // Get current scroll position
      var windowHeight = window.innerHeight; // Get viewport height

      targets.forEach(function(target) {
        var pos = target.getBoundingClientRect().top + scroll; // Get the element's position from the top of the document
        console.log(pos);
        if (scroll > pos - windowHeight + offset) { // Check if the scroll position meets the condition
          target.dataset.animated = 'true'; // 条件を満たしたらdata属性を追加
        }
      });
    }

    // Listen for scroll events
    window.addEventListener('scroll', checkAnimation);

    // Trigger the function on initial load
    checkAnimation();
  });
}
